import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/images/AboutService/left.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/images/AboutService/right.png");
;
import(/* webpackMode: "eager", webpackExports: ["Beginning"] */ "/app/src/sections/Beginning/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewTasks"] */ "/app/src/sections/NewTasks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Decore"] */ "/app/src/sections/PrizeBanner/components/Decore/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskTypeCard"] */ "/app/src/sections/TaskTypes/components/TaskTypeCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/TaskTypes/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/RegistrationBanner/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/AboutService/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/PrizeBanner/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/Main/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/Main/components/InputRow/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Vehicles"] */ "/app/src/sections/Vehicles/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","src"] */ "/app/src/sections/WhyWe/images/tractor-small.png");
;
import(/* webpackMode: "eager", webpackExports: ["default","src"] */ "/app/src/sections/WhyWe/images/tractors.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/WhyWe/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/PartnerBanner/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["FaqItem"] */ "/app/src/ui/FAQ/FaqItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/FAQ/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/Main/components/DescriptionRow/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/ui/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RedirectLink"] */ "/app/src/ui/RedirectLink/RedirectLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/Title/styles.module.scss");
